import React from "react";
import Heading from "../../components-v2/Base/HeadingBuilder";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";

import eclipse from "../../images/svg/blurred-eclipse.svg";
import figure from "../../images/svg/person-chilling.svg";

const AffiliateThankYou = () => {
  return (
    <section className="relative">
      <div className="mt-40 text-center px-4 lg:px-10 relative z-1">
        <Heading injectionType={1} withBlueSymbol symbol="!">
          Referral received
        </Heading>

        <p className="text-p text-black-300 max-w-[500px] text-center mx-auto">
          Thank you for putting in the time and effort to help grow the SpotOn
          family. After your referred business activates a SpotOn account, we’ll
          follow up with you about your referral reward bonus.
        </p>

        <CtaPrimary
          className="mx-auto mt-6 md:mt-12"
          title="Continue to SpotOn"
          target="/"
        />

        <img
          src={figure}
          alt="figure"
          className="mx-auto max-w-[730px] w-full h-auto mt-20 md:mt-12 mb-20 md:mb-32"
        />
      </div>
    </section>
  );
};

export default AffiliateThankYou;
