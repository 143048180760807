import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import AffiliateThankYou from "../../page-components/employee-affiliates/thank-you";
import bg from "../../images/svg/vector-07.svg";

const AffiliatePage = () => {
  return (
    <Layout isLanding useNewLPHeader className="non-fixed-width">
      <SEO
        title="SpotOn Employee Affiliate"
        description="Do you have a friend or family member who owns a business and would like to refer them to SpotOn? Perhaps you have built a friendly relationship with the businesses you frequent in your community (restaurants, local coffee shop, barber, mechanic etc). Launching this month, you can now earn up to $1000 when you refer a business to SpotOn upon activation."
      />
      <img
        src={bg}
        alt="background"
        className="hidden md:block absolute top-20 right-0 "
      />
      <AffiliateThankYou />
    </Layout>
  );
};

export default AffiliatePage;
